import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';

export const useBillingStore = defineStore('billing', () => {
  const api = useApi();

  const selectedPlan = ref(null);

  const getActivePlans = async () => {
    const { data } = await api.get('/plans/active');
    return data;
  };

  const createStripeCustomer = async () => {
    const { data } = await api.post('/billing/create-customer', {});
    return data;
  };

  const createSubscription = async (
    { paymentMethodId, planPriceId, userId }: { paymentMethodId: string, planPriceId: string, userId: string },
  ) => {
    const { data } = await api.post('/billing/create-subscription', { paymentMethodId, planPriceId, userId });
    return data;
  };

  const createFreeSubscription = async () => {
    const { data } = await api.post('/billing/create-free-subscription');
    return data;
  };

  const cancelSubscription = async ({ inactivateAlerts = false }: { inactivateAlerts?: boolean }) => {
    const { data } = await api.post('/billing/cancel-subscription', { inactivateAlerts });
    return data;
  };

  const reactivateSubscription = async ({ activateAlerts = false }: { activateAlerts?: boolean }) => {
    const { data } = await api.post('/billing/reactivate-subscription', { activateAlerts });
    return data;
  };

  const changePlan = async ({ paymentMethodId, newPriceId }: { paymentMethodId: string, newPriceId: string }) => {
    const { data } = await api.post('/billing/change-plan', { paymentMethodId, newPriceId });
    return data;
  };

  const reset = () => {
    selectedPlan.value = null;
  };

  return {
    selectedPlan,
    getActivePlans,
    createStripeCustomer,
    createSubscription,
    createFreeSubscription,
    cancelSubscription,
    reactivateSubscription,
    changePlan,
    reset,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBillingStore, import.meta.hot));
}
