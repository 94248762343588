import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAutocomplete, LazyContainer, LazyCurrencyInput, LazyLoadingSpinner, LazySkipTo } from '#components'
const lazyGlobalComponents = [
  ["Autocomplete", LazyAutocomplete],
["Container", LazyContainer],
["CurrencyInput", LazyCurrencyInput],
["LoadingSpinner", LazyLoadingSpinner],
["SkipTo", LazySkipTo],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
