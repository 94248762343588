import { acceptHMRUpdate, defineStore } from 'pinia';

import { useAdminStore } from './admin';
import { useAlertsStore } from './alerts';
import { useAuthStore } from './auth';
import { useBillingStore } from './billing';
import { useCertificationsStore } from './certifications';
import { useDirectoryStore } from './directory';
import { useJobsStore } from './jobs';
import { useMarketingStore } from './marketing';
import { usePlansStore } from './plans';
import { useProfileStore } from './profile';
import { useSettingsStore } from './settings';
import { useUserStore } from './user';
import { useVehiclesStore } from './vehicles';

export const useMainStore = defineStore('main', () => {
  // TODO: update stores to add reset method, if applicable
  const clearProtectedStores = () => {
    [
      useAdminStore(),
      useAlertsStore(),
      useAuthStore(),
      useBillingStore(),
      useCertificationsStore(),
      useJobsStore(),
      usePlansStore(),
      useProfileStore(),
      useSettingsStore(),
      useUserStore(),
      useVehiclesStore(),
      useMarketingStore(),
      useDirectoryStore(),
    ].forEach((store) => {
      try {
        store.reset();
      } catch (e) {
        console.error('e', e, 'store', store);
      }
    });
  };

  return {
    clearProtectedStores,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMainStore, import.meta.hot));
}
