import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';

export const useMarketingStore = defineStore('marketing', () => {
  const api = useApi();

  const loading = ref(false);
  const loadsPerMonth = ref('1000+');
  const alertsPerMonth = ref('650,000+');
  const totalUsers = ref('6000+');

  const getLoadsPerMonth = async () => {
    try {
      loading.value = true;

      const resp = await api.get('/jobs/count/days');
      if (resp && resp.data) {
        loadsPerMonth.value = resp.data.toLocaleString();
      }
    } finally {
      loading.value = false;
    }
  };

  const getAlertsPerMonth = async () => {
    try {
      loading.value = true;

      const resp = await api.get('/alert-messages/count/days');
      if (resp && resp.data) {
        alertsPerMonth.value = resp.data.toLocaleString();
      }
    } finally {
      loading.value = false;
    }
  };

  const getTotalUsers = async () => {
    try {
      loading.value = true;

      const totalUsersResp = await Promise.all(
        [
          api.get('/users/count', { params: { userType: 'driver' } }),
          api.get('/users/count', { params: { userType: 'shipper' } }),
        ],
      );

      if (totalUsersResp[0] && totalUsersResp[1]) {
        totalUsers.value = (totalUsersResp[0].data + totalUsersResp[1].data).toLocaleString();
      }
    } finally {
      loading.value = false;
    }
  };

  const reset = () => {
    loadsPerMonth.value = '1000+';
    alertsPerMonth.value = '650,000+';
    totalUsers.value = '6000+';
  };

  return {
    loading,
    loadsPerMonth,
    alertsPerMonth,
    totalUsers,
    getLoadsPerMonth,
    getAlertsPerMonth,
    getTotalUsers,
    reset,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMarketingStore, import.meta.hot));
}
