import revive_payload_client_4sVQNw7RlN from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/opt/buildhome/repo/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/opt/buildhome/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/opt/buildhome/repo/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_HQNyLQlVwt from "/opt/buildhome/repo/node_modules/nuxt3-meta-pixel/dist/runtime/plugin.mjs";
import plugin_client_W5VNcGB8FU from "/opt/buildhome/repo/node_modules/nuxt-3-intercom/dist/runtime/plugin.client.mjs";
import plugin_client_LcKgStRyi6 from "/opt/buildhome/repo/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import pwa_icons_plugin_LnrhIJeMG7 from "/opt/buildhome/repo/.nuxt/pwa-icons-plugin.ts";
import pwa_client_ZuYw5i1i7i from "/opt/buildhome/repo/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_PSbK9A48ZQ from "/opt/buildhome/repo/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import plugin_client_1uFu2M4PVa from "/opt/buildhome/repo/node_modules/@nuxtjs/prismic/dist/runtime/plugin.client.js";
import plugin_PWMUhiA9tt from "/opt/buildhome/repo/node_modules/@nuxtjs/prismic/dist/runtime/plugin.js";
import axios_QMFgzss1s4 from "/opt/buildhome/repo/plugins/axios.ts";
import calendar_client_i1uzu9e7Tc from "/opt/buildhome/repo/plugins/calendar.client.ts";
import fontawesome_cn2c4tOOHz from "/opt/buildhome/repo/plugins/fontawesome.ts";
import init_grdyAU8lhf from "/opt/buildhome/repo/plugins/init.ts";
import maska_UHaKf2z1iQ from "/opt/buildhome/repo/plugins/maska.ts";
import sentry_client_shVUlIjFLk from "/opt/buildhome/repo/plugins/sentry.client.ts";
import skipTo_aeIjxXE6sT from "/opt/buildhome/repo/plugins/skipTo.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  plugin_HQNyLQlVwt,
  plugin_client_W5VNcGB8FU,
  plugin_client_LcKgStRyi6,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_ZuYw5i1i7i,
  plugin_PSbK9A48ZQ,
  plugin_client_1uFu2M4PVa,
  plugin_PWMUhiA9tt,
  axios_QMFgzss1s4,
  calendar_client_i1uzu9e7Tc,
  fontawesome_cn2c4tOOHz,
  init_grdyAU8lhf,
  maska_UHaKf2z1iQ,
  sentry_client_shVUlIjFLk,
  skipTo_aeIjxXE6sT
]