import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';

export const useVehiclesStore = defineStore('vehicles', () => {
  const api = useApi();

  const loading = ref(false);
  const vehicleFormData = ref({
    id: null,
    year: null,
    make: null,
    model: null,
  });
  const vehicles = ref([]);
  const vehicleFormOpen = ref(false);
  const submitting = ref(false);

  const getMyVehicles = async () => {
    try {
      loading.value = true;
      const resp = await api.get('/vehicles/me');
      if (resp && resp.data) {
        vehicles.value = resp.data;
      }
    } finally {
      loading.value = false;
    }
  };

  const createVehicle = async (payload: any) => {
    const { trackIntercomEvent } = useIntercomStore();
    try {
      submitting.value = true;
      await api.post('/vehicles', payload);
      await getMyVehicles();
      vehicleFormOpen.value = false;
      clearVehicleFormData();

      trackIntercomEvent({ event: 'Vehicle Added', data: payload });
    } finally {
      submitting.value = false;
    }
  };

  const updateVehicle = async (payload: any) => {
    const { trackIntercomEvent } = useIntercomStore();

    try {
      submitting.value = true;
      await api.put(`/vehicles/${payload.id}`, payload.data);
      await getMyVehicles();
      vehicleFormOpen.value = false;
      clearVehicleFormData();

      trackIntercomEvent({ event: 'Vehicle Updated', data: payload });
    } finally {
      submitting.value = false;
    }
  };

  const deleteVehicle = async (payload: any) => {
    const { trackIntercomEvent } = useIntercomStore();

    try {
      submitting.value = true;
      await api.delete(`/vehicles/${payload.id}`, payload.data);
      await getMyVehicles();
      vehicleFormOpen.value = false;
      clearVehicleFormData();

      trackIntercomEvent({ event: 'Vehicle Deleted', data: payload });
    } finally {
      submitting.value = false;
    }
  };

  const clearVehicleFormData = () => {
    vehicleFormData.value = {
      id: null,
      year: null,
      make: null,
      model: null,
    };
  };

  const reset = () => {
    vehicles.value = [];
    vehicleFormOpen.value = false;
    clearVehicleFormData();
  };

  return {
    loading,
    vehicleFormData,
    vehicles,
    vehicleFormOpen,
    submitting,
    getMyVehicles,
    createVehicle,
    updateVehicle,
    deleteVehicle,
    clearVehicleFormData,
    reset,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useVehiclesStore, import.meta.hot));
}
