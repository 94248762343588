import { acceptHMRUpdate, defineStore } from 'pinia';
import { computed, ref } from 'vue';
import _isEmpty from 'lodash/isEmpty.js';

export const useUserStore = defineStore('user', () => {
  const api = useApi();

  const userData = ref({
    firstName: null,
    lastName: null,
    type: null,
    company: null,
    email: null,
    isEmailVerified: false,
    phoneNumber: null,
    isPhoneNumberVerified: false,
    isActive: false,
    isSuperuser: false,
    isSalesperson: false,
    plan: {},
    subscription: {},
    activePromo: {},
    createdAt: null,
    hasPaymentMethod: null,
  });
  const emailBeingVerified = ref(null);

  const getUserData = async () => {
    const { updateIntercom } = useIntercomStore();

    const resp = await api.get('/users/me');

    const { data } = resp;

    if (data) {
      userData.value = {
        firstName: data.firstName,
        lastName: data.lastName,
        type: data.type,
        company: data.company,
        email: data.email,
        isEmailVerified: data.isEmailVerified,
        phoneNumber: data.phoneNumber,
        isPhoneNumberVerified: data.isPhoneNumberVerified,
        isActive: data.isActive,
        isSuperuser: data.isSuperuser,
        isSalesperson: data.isSalesperson,
        plan: data.plan,
        activePromo: data.activePromo,
        subscription: data.subscription,
        createdAt: data.createdAt,
        hasPaymentMethod: data.hasPaymentMethod,
      };

      const general = {
        'Account Type': accountTypeDisplay.value,
        'Account Status': (userData.value.subscription && userData.value?.subscription?.status) || 'unknown',
        name: fullName.value,
        email: userData.value.email,
        'Email Verified': userData.value.isEmailVerified,
        phone: `+1${userData.value.phoneNumber}`,
        'Phone Verified': userData.value.isPhoneNumberVerified,
        'Company Name': userData.value.company,
        'Payment Method': userData.value.hasPaymentMethod,
      };

      const driver = userData.value.type === 'driver'
        ? {
          'Free Plan': isOnFreePlan.value,
          'Trial end': trialEnd.value,
        }
        : {};


      updateIntercom({ ...general, ...driver });
    }
  };

  const updateUser = async (data) => {
    const { trackIntercomEvent } = useIntercomStore();

    await api.put('/users/me', data);

    trackIntercomEvent({ event: 'User Updated' });
  };

  const clearUserData = () => {
    userData.value = {
      firstName: null,
      lastName: null,
      type: null,
      company: null,
      email: null,
      isEmailVerified: false,
      phoneNumber: null,
      isPhoneNumberVerified: false,
      isActive: false,
      isSuperuser: false,
      isSalesperson: false,
      plan: {},
      subscription: {},
      activePromo: {},
      createdAt: null,
      hasPaymentMethod: null,
    };
  };

  const registerUser = async (payload) => {
    const { logIn } = useAuthStore();
    const { createStripeCustomer, createFreeSubscription } = useBillingStore();

    const params = {};

    if (payload.promo) {
      params.promo = payload.promo;
    }

    const { data: userData } = await api.post('/users', payload.data, { params });

    // can happen when user is a load manager, they're set to inactive by default
    if (!userData.isActive) {
      return;
    }

    // helps with a problem found when login request fails, where the stripe user and subscription won't be created
    let loginRetryAttempts = 1;
    const allowedLoginAttempts = 3;

    const { user } = payload.data;

    while (loginRetryAttempts <= allowedLoginAttempts) {
      try {
        await logIn({ email: user.email, password: user.password });
        break;
      } catch (e) {
        loginRetryAttempts++;
      }
    }

    if (loginRetryAttempts > allowedLoginAttempts) {
      return;
    }

    await createStripeCustomer();
    await createFreeSubscription();
  };

  const checkIfEmailExists = async (email: string) => {
    const { data } = await api.get(`/users/email-exists/${email}`);
    return data;
  };

  const getUserPromo = async () => {
    const { data } = await api.get('/user-promos/me/active');
    return data;
  };

  const fullName = computed(() => {
    return userData.value.firstName && userData.value.lastName ? `${userData.value.firstName} ${userData.value.lastName}` : null;
  });

  const promoDuration = computed(() => {
    return userData.value.activePromo?.promo?.duration || 0;
  });

  const hasActivePlan = computed(() => {
    return userData.value.plan && !_isEmpty(userData.value.plan) && ![undefined, null].includes(userData.value.plan.id);
  });

  const accountTypeDisplay = computed(() => userData.value.type === 'shipper' ? 'Load Manager' : 'Driver / Escort');

  const isOnFreePlan = computed(() => !_isEmpty(userData.value.plan) && !userData.value.plan.rate);
  const isTrialing = computed(() => userData.value.subscription?.status === 'trialing');
  const trialEnd = computed(() => userData.value.subscription?.trialEnd || null);

  const reset = () => {
    clearUserData();
    emailBeingVerified.value = null;
  };

  return {
    userData,
    emailBeingVerified,
    getUserData,
    updateUser,
    clearUserData,
    registerUser,
    checkIfEmailExists,
    getUserPromo,
    reset,
    fullName,
    promoDuration,
    hasActivePlan,
    accountTypeDisplay,
    isOnFreePlan,
    isTrialing,
    trialEnd,
  };
}, {
  persist: {
    pick: ['userData'],
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
