import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';

export const usePlansStore = defineStore('plans', () => {
  const api = useApi();

  const plans = ref([]);

  const getAllPlans = async () => {
    const { data } = await api.get('/plans');
    plans.value = data;
  };

  const reset = () => {
    plans.value = [];
  };

  return {
    plans,
    getAllPlans,
    reset,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePlansStore, import.meta.hot));
}
