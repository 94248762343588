import * as Sentry from '@sentry/vue';
import * as pkg from '~/package.json';
import { browserTracingIntegration } from '@sentry/browser';
import { defineNuxtPlugin } from '#app';
import { setTag } from '@sentry/core';

export default defineNuxtPlugin((nuxtApp) => {
  const { dsn } = nuxtApp.$config.public.sentry;
  const release = `${pkg.name}@${pkg.version}`;
  const environment = nuxtApp.$config.public.ENVIRONMENT;
  const integrations = [browserTracingIntegration()];
  const sampleRate = 1;
  const tracesSampleRate = 1;

  Sentry.init({
    dsn,
    release,
    environment,
    integrations,
    sampleRate,
    tracesSampleRate,
    app: nuxtApp.vueApp,
  });
  setTag('frontOrBack', 'front');
});
