import { acceptHMRUpdate, defineStore } from 'pinia';

import type { LoginPayload } from '~/types';

export const useAuthStore = defineStore('auth', () => {
  const api = useApi();
  const intercom = useIntercom();

  const loggedIn = useLocalStorage('loggedIn', false);
  const isApp = ref(false);
  const isAndroid = ref(false);
  const serverUserAgent = ref();

  const logIn = async (payload: LoginPayload) => {
    const { getUserData } = useUserStore();
    const { trackIntercomEvent } = useIntercomStore();

    const formData = new FormData();
    formData.set('username', payload.email);
    formData.set('password', payload.password);

    await api.post('/login/access-token', formData);

    await getUserData();
    loggedIn.value = true;

    trackIntercomEvent({ event: 'Logged In' });
  };

  const testToken = async () => {
    const { getUserData } = useUserStore();

    try {
      await api.post('/login/test-token');

      await getUserData();
      loggedIn.value = true;
      return;
    } catch (e) {
      // no need to do anything here, it's handled at $axios level
    }

    loggedIn.value = false;
  };

  const logOut = async () => {
    const { trackIntercomEvent } = useIntercomStore();
    const { clearUserData } = useUserStore();

    await api.post('/logout');
    loggedIn.value = false;

    clearUserData();
    trackIntercomEvent({ event: 'Logged Out' });

    // if (isAndroid.value) {
    // $router.push('reset://');
    // }

    if (intercom && intercom.ready) {
      intercom.shutdown();
    }
  };

  const sendEmailVerification = async (newEmail: string) => {
    const { trackIntercomEvent } = useIntercomStore();

    await api.post(`/users/send-email-verification/${newEmail}`);

    trackIntercomEvent({ event: 'Requested Email Verification' });
  };

  const sendPhoneVerification = async (newPhoneNumber: number | string) => {
    const { trackIntercomEvent } = useIntercomStore();

    await api.post(`/users/send-phone-verification/${newPhoneNumber}`);

    trackIntercomEvent({ event: 'Requested Phone Verification' });
  };

  const recoverPassword = async (email: string) => {
    await api.post(`/password-recovery/${email}`);
  };

  const reset = () => {
    loggedIn.value = false;
  };

  return {
    loggedIn,
    isApp,
    isAndroid,
    serverUserAgent,
    logIn,
    testToken,
    logOut,
    sendEmailVerification,
    sendPhoneVerification,
    recoverPassword,
    reset,
  };
}, {
  persist: {
    pick: ['loggedIn'],
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
