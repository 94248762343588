import { acceptHMRUpdate, defineStore } from 'pinia';
import _isEmpty from 'lodash/isEmpty.js';

interface IntercomEvent {
  event?: string;
  data?: Record<string, any>;
}

export const useIntercomStore = defineStore('intercom', () => {
  const intercom = useIntercom();
  const sentry = useSentry();

  const showIntercom = () => {
    try {
      if (!intercom || !intercom.ready) { return; }
      intercom.show();
    } catch (e) {
      sentry?.captureException(e);
    }
  };

  const trackIntercomEvent = (payload: IntercomEvent = {}) => {
    try {
      if (_isEmpty(payload) || !intercom || !intercom.ready) {
        return;
      }

      const data = payload.data || {};
      const interObj: Record<string, string | string[]> = {};

      for (const [key, value] of Object.entries(data)) {
        interObj[key] = Array.isArray(value) ? value.toString() : value;
      }

      intercom.trackEvent(payload.event || 'Empty event', interObj);
    } catch (e) {
      sentry?.captureException(e);
    }
  };

  const updateIntercom = (payload: Record<string, any>) => {
    try {
      if (!intercom || !intercom.ready) { return; }
      if (!intercom.userData.email) {
        intercom.boot(payload);
      } else {
        intercom.update(payload);
      }
    } catch (e) {
      sentry?.captureException(e);
    }
  };

  return {
    showIntercom,
    trackIntercomEvent,
    updateIntercom,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useIntercomStore, import.meta.hot));
}
