import { default as PrismicPreviewSBglaf9VTYMeta } from "/opt/buildhome/repo/node_modules/@nuxtjs/prismic/dist/runtime/PrismicPreview.vue?macro=true";
import { default as accessibilityXr1RkVknkKMeta } from "/opt/buildhome/repo/pages/accessibility.vue?macro=true";
import { default as appCIzZhzdW1jMeta } from "/opt/buildhome/repo/pages/app.vue?macro=true";
import { default as _91uid_937u18pI1ReiMeta } from "/opt/buildhome/repo/pages/blog/[uid].vue?macro=true";
import { default as _91uid_93qftmxZE7JgMeta } from "/opt/buildhome/repo/pages/blog/category/[uid].vue?macro=true";
import { default as indexnCitm8tluDMeta } from "/opt/buildhome/repo/pages/blog/index.vue?macro=true";
import { default as _91num_93Raxyd27qfTMeta } from "/opt/buildhome/repo/pages/blog/page/[num].vue?macro=true";
import { default as contactupLWW5VsuQMeta } from "/opt/buildhome/repo/pages/contact.vue?macro=true";
import { default as cookiesZLbBnefQgdMeta } from "/opt/buildhome/repo/pages/cookies.vue?macro=true";
import { default as plansBh3qWLhw5BMeta } from "/opt/buildhome/repo/pages/dashboard/admin/plans.vue?macro=true";
import { default as users0o3OCenirZMeta } from "/opt/buildhome/repo/pages/dashboard/admin/users.vue?macro=true";
import { default as admin9VkxcRL1hOMeta } from "/opt/buildhome/repo/pages/dashboard/admin.vue?macro=true";
import { default as alertsHCnz3JhSuIMeta } from "/opt/buildhome/repo/pages/dashboard/alerts.vue?macro=true";
import { default as appKqt2S3JHUqMeta } from "/opt/buildhome/repo/pages/dashboard/app.vue?macro=true";
import { default as directoryhdb5UzHO1KMeta } from "/opt/buildhome/repo/pages/dashboard/directory.vue?macro=true";
import { default as loadsMFOnApv6FqMeta } from "/opt/buildhome/repo/pages/dashboard/loads.vue?macro=true";
import { default as post_45loadi6TB6WClCQMeta } from "/opt/buildhome/repo/pages/dashboard/post-load.vue?macro=true";
import { default as profileqOEG4tKnc1Meta } from "/opt/buildhome/repo/pages/dashboard/profile.vue?macro=true";
import { default as referral3QRiRzCrFaMeta } from "/opt/buildhome/repo/pages/dashboard/referral.vue?macro=true";
import { default as settingsHEo5lq1OdBMeta } from "/opt/buildhome/repo/pages/dashboard/settings.vue?macro=true";
import { default as upgradepmAcIpS7mqMeta } from "/opt/buildhome/repo/pages/dashboard/upgrade.vue?macro=true";
import { default as forgot_45passwordcBWiw0xyOQMeta } from "/opt/buildhome/repo/pages/forgot-password.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as load_45boardwIIR7YXOnOMeta } from "/opt/buildhome/repo/pages/load-board.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as pilot_45carsLev2zBzZziMeta } from "/opt/buildhome/repo/pages/pilot-cars.vue?macro=true";
import { default as plan_45errorLDLRCQm0CJMeta } from "/opt/buildhome/repo/pages/plan-error.vue?macro=true";
import { default as post_45a_45loadC4YSV3WVyhMeta } from "/opt/buildhome/repo/pages/post-a-load.vue?macro=true";
import { default as pricingQrzbg66BTdMeta } from "/opt/buildhome/repo/pages/pricing.vue?macro=true";
import { default as privacy69l1Gm1VSzMeta } from "/opt/buildhome/repo/pages/privacy.vue?macro=true";
import { default as registerynsVf0yvMzMeta } from "/opt/buildhome/repo/pages/register.vue?macro=true";
import { default as indexzIVGDwloyUMeta } from "/opt/buildhome/repo/pages/reset-password/[token]/index.vue?macro=true";
import { default as select_45planfikW8G99gaMeta } from "/opt/buildhome/repo/pages/select-plan.vue?macro=true";
import { default as termsjw1825O2ZdMeta } from "/opt/buildhome/repo/pages/terms.vue?macro=true";
import { default as indexBktGoH7wosMeta } from "/opt/buildhome/repo/pages/upgrade-now/[token]/index.vue?macro=true";
import { default as completeXgfu2jfxzaMeta } from "/opt/buildhome/repo/pages/upgrade-now/complete.vue?macro=true";
import { default as indexkLTVf4CPzDMeta } from "/opt/buildhome/repo/pages/verify/email/[token]/index.vue?macro=true";
import { default as indexIG0AxEpeHzMeta } from "/opt/buildhome/repo/pages/verify/phone/[token]/index.vue?macro=true";
import { default as verifying_45emailoVIa20TIN8Meta } from "/opt/buildhome/repo/pages/verifying-email.vue?macro=true";
import { default as why_45choose_45usrFNUTyAtgZMeta } from "/opt/buildhome/repo/pages/why-choose-us.vue?macro=true";
export default [
  {
    name: "prismic-preview",
    path: "/preview",
    component: () => import("/opt/buildhome/repo/node_modules/@nuxtjs/prismic/dist/runtime/PrismicPreview.vue")
  },
  {
    name: "accessibility",
    path: "/accessibility",
    meta: accessibilityXr1RkVknkKMeta || {},
    component: () => import("/opt/buildhome/repo/pages/accessibility.vue")
  },
  {
    name: "app",
    path: "/app",
    meta: appCIzZhzdW1jMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app.vue")
  },
  {
    name: "blog-uid",
    path: "/blog/:uid()",
    meta: _91uid_937u18pI1ReiMeta || {},
    component: () => import("/opt/buildhome/repo/pages/blog/[uid].vue")
  },
  {
    name: "blog-category-uid",
    path: "/blog/category/:uid()",
    meta: _91uid_93qftmxZE7JgMeta || {},
    component: () => import("/opt/buildhome/repo/pages/blog/category/[uid].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexnCitm8tluDMeta || {},
    component: () => import("/opt/buildhome/repo/pages/blog/index.vue")
  },
  {
    name: "blog-page-num",
    path: "/blog/page/:num()",
    meta: _91num_93Raxyd27qfTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/blog/page/[num].vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactupLWW5VsuQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/contact.vue")
  },
  {
    name: "cookies",
    path: "/cookies",
    meta: cookiesZLbBnefQgdMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cookies.vue")
  },
  {
    name: "dashboard-admin",
    path: "/dashboard/admin",
    meta: admin9VkxcRL1hOMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/admin.vue"),
    children: [
  {
    name: "dashboard-admin-plans",
    path: "plans",
    meta: plansBh3qWLhw5BMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/admin/plans.vue")
  },
  {
    name: "dashboard-admin-users",
    path: "users",
    meta: users0o3OCenirZMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/admin/users.vue")
  }
]
  },
  {
    name: "dashboard-alerts",
    path: "/dashboard/alerts",
    meta: alertsHCnz3JhSuIMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/alerts.vue")
  },
  {
    name: "dashboard-app",
    path: "/dashboard/app",
    meta: appKqt2S3JHUqMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/app.vue")
  },
  {
    name: "dashboard-directory",
    path: "/dashboard/directory",
    meta: directoryhdb5UzHO1KMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/directory.vue")
  },
  {
    name: "dashboard-loads",
    path: "/dashboard/loads",
    meta: loadsMFOnApv6FqMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/loads.vue")
  },
  {
    name: "dashboard-post-load",
    path: "/dashboard/post-load",
    meta: post_45loadi6TB6WClCQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/post-load.vue")
  },
  {
    name: "dashboard-profile",
    path: "/dashboard/profile",
    meta: profileqOEG4tKnc1Meta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/profile.vue")
  },
  {
    name: "dashboard-referral",
    path: "/dashboard/referral",
    meta: referral3QRiRzCrFaMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/referral.vue")
  },
  {
    name: "dashboard-settings",
    path: "/dashboard/settings",
    meta: settingsHEo5lq1OdBMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/settings.vue")
  },
  {
    name: "dashboard-upgrade",
    path: "/dashboard/upgrade",
    meta: upgradepmAcIpS7mqMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/upgrade.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordcBWiw0xyOQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/forgot-password.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexniDiYCWjuTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "load-board",
    path: "/load-board",
    meta: load_45boardwIIR7YXOnOMeta || {},
    component: () => import("/opt/buildhome/repo/pages/load-board.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login4dfr6kRqBoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/login.vue")
  },
  {
    name: "pilot-cars",
    path: "/pilot-cars",
    meta: pilot_45carsLev2zBzZziMeta || {},
    component: () => import("/opt/buildhome/repo/pages/pilot-cars.vue")
  },
  {
    name: "plan-error",
    path: "/plan-error",
    meta: plan_45errorLDLRCQm0CJMeta || {},
    component: () => import("/opt/buildhome/repo/pages/plan-error.vue")
  },
  {
    name: "post-a-load",
    path: "/post-a-load",
    meta: post_45a_45loadC4YSV3WVyhMeta || {},
    component: () => import("/opt/buildhome/repo/pages/post-a-load.vue")
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricingQrzbg66BTdMeta || {},
    component: () => import("/opt/buildhome/repo/pages/pricing.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacy69l1Gm1VSzMeta || {},
    component: () => import("/opt/buildhome/repo/pages/privacy.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerynsVf0yvMzMeta || {},
    component: () => import("/opt/buildhome/repo/pages/register.vue")
  },
  {
    name: "reset-password-token",
    path: "/reset-password/:token()",
    meta: indexzIVGDwloyUMeta || {},
    component: () => import("/opt/buildhome/repo/pages/reset-password/[token]/index.vue")
  },
  {
    name: "select-plan",
    path: "/select-plan",
    meta: select_45planfikW8G99gaMeta || {},
    component: () => import("/opt/buildhome/repo/pages/select-plan.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsjw1825O2ZdMeta || {},
    component: () => import("/opt/buildhome/repo/pages/terms.vue")
  },
  {
    name: "upgrade-now-token",
    path: "/upgrade-now/:token()",
    meta: indexBktGoH7wosMeta || {},
    component: () => import("/opt/buildhome/repo/pages/upgrade-now/[token]/index.vue")
  },
  {
    name: "upgrade-now-complete",
    path: "/upgrade-now/complete",
    meta: completeXgfu2jfxzaMeta || {},
    component: () => import("/opt/buildhome/repo/pages/upgrade-now/complete.vue")
  },
  {
    name: "verify-email-token",
    path: "/verify/email/:token()",
    meta: indexkLTVf4CPzDMeta || {},
    component: () => import("/opt/buildhome/repo/pages/verify/email/[token]/index.vue")
  },
  {
    name: "verify-phone-token",
    path: "/verify/phone/:token()",
    meta: indexIG0AxEpeHzMeta || {},
    component: () => import("/opt/buildhome/repo/pages/verify/phone/[token]/index.vue")
  },
  {
    name: "verifying-email",
    path: "/verifying-email",
    meta: verifying_45emailoVIa20TIN8Meta || {},
    component: () => import("/opt/buildhome/repo/pages/verifying-email.vue")
  },
  {
    name: "why-choose-us",
    path: "/why-choose-us",
    meta: why_45choose_45usrFNUTyAtgZMeta || {},
    component: () => import("/opt/buildhome/repo/pages/why-choose-us.vue")
  }
]