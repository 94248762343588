import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';

export const useProfileStore = defineStore('profile', () => {
  const api = useApi();

  const loading = ref(false);
  const profile = ref({
    id: null,
    about: null,
    serviceStates: null,
    servicesOffered: [],
    insuranceCompany: null,
    insuranceAmount: null,
    includeInDirectory: false,
  });
  const submitting = ref(false);

  const getProfile = async () => {
    const { updateIntercom } = useIntercomStore();

    try {
      loading.value = true;

      const resp = await api.get('/profile/me');
      if (resp && resp.data) {
        profile.value = resp.data;

        updateIntercom({
          'Include In Directory': profile.value.includeInDirectory,
          'About Profile': profile.value.about,
          'Service States': Array.isArray(profile.value.serviceStates) ? profile.value.serviceStates.toString() : null,
          'Services Offered': profile.value.servicesOffered.toString(),
        });
      }
    } finally {
      loading.value = false;
    }
  };

  const updateProfile = async (payload: any) => {
    const { trackIntercomEvent } = useIntercomStore();

    try {
      submitting.value = true;
      await api.put('/profile', payload);
      await getProfile();

      trackIntercomEvent({ event: 'Profile Updated', data: payload });
    } finally {
      submitting.value = false;
    }
  };

  const reset = () => {
    profile.value = {
      id: null,
      about: null,
      serviceStates: null,
      servicesOffered: [],
      insuranceCompany: null,
      insuranceAmount: null,
      includeInDirectory: false,
    };
  };

  return {
    loading,
    profile,
    submitting,
    getProfile,
    updateProfile,
    reset,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useProfileStore, import.meta.hot));
}
