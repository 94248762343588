import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';

import type { ApiPaginationData, ApiParams } from '~/types/api';

export const useAdminStore = defineStore('admin', () => {
  const api = useApi();

  const users = ref([]);
  const userApiParams = ref<ApiParams>({
    page: 1,
    pageSize: 12,
  });
  const userPaginationData = ref<ApiPaginationData>({
    total: 0,
    hasPrevious: false,
    previousPage: null,
    hasNext: false,
    nextPage: null,
    pages: 0,
  });

  const getUsers = async () => {
    const resp = await api.get('/users', { params: userApiParams.value });

    if (resp && resp.data) {
      users.value = resp.data.items;
      userPaginationData.value = {
        total: resp.data.total,
        hasPrevious: resp.data.hasPrevious,
        previousPage: resp.data.previousPage,
        hasNext: resp.data.hasNext,
        nextPage: resp.data.nextPage,
        pages: resp.data.pages,
      };
    }
  };

  const clearUserApiParams = () => {
    userApiParams.value.page = 1;
    userApiParams.value.pageSize = 12;
  };

  const clearUserPaginationData = () => {
    userPaginationData.value.total = 0;
    userPaginationData.value.hasPrevious = false;
  };

  const reset = () => {
    users.value = [];
    clearUserApiParams();
    clearUserPaginationData();
  };

  return {
    users,
    userApiParams,
    userPaginationData,
    getUsers,
    clearUserApiParams,
    clearUserPaginationData,
    reset,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAdminStore, import.meta.hot));
}
