import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';

import type { SettingsData } from '~/types/settings';

export const useSettingsStore = defineStore('settings', () => {
  const api = useApi();

  const loading = ref(false);
  const settings = ref<SettingsData>({
    notifyByEmail: false,
    notifyByText: false,
    onlyShowOpenLoads: false,
  });
  const submitting = ref(false);

  const getSettings = async () => {
    const { updateIntercom } = useIntercomStore();

    try {
      loading.value = true;

      const profile = await api.get('/settings-profiles');

      if (profile && profile.data) {
        settings.value = profile.data;

        updateIntercom({
          'Notify By Email': settings.value.notifyByEmail,
          'Notify By Text': settings.value.notifyByText,
          'Only Show Open Loads': settings.value.onlyShowOpenLoads,
        });
      }
    } finally {
      loading.value = false;
    }
  };

  const updateSettings = async (data: any) => {
    const { trackIntercomEvent } = useIntercomStore();

    try {
      submitting.value = true;
      await api.put('/settings-profiles', data);

      trackIntercomEvent({ event: 'Settings Updated', data });
    } finally {
      submitting.value = false;
    }
  };

  const reset = () => {
    settings.value = {
      notifyByEmail: false,
      notifyByText: false,
      onlyShowOpenLoads: false,
    };
    loading.value = false;
    submitting.value = false;
  };

  return {
    loading,
    settings: settings as unknown as SettingsData,
    submitting,
    getSettings,
    updateSettings,
    reset,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSettingsStore, import.meta.hot));
}
