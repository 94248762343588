import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';

export const useCertificationsStore = defineStore('certifications', () => {
  const api = useApi();

  const loading = ref(false);
  const certificationFormData = ref({
    id: null,
    title: null,
    type: null,
    expirationDate: null,
    issuedDate: null,
    issuedBy: null,
    validIn: [],
  });
  const certifications = ref([]);
  const certificationFormOpen = ref(false);
  const submitting = ref(false);

  const getMyCertifications = async () => {
    try {
      loading.value = true;
      const resp = await api.get('/certifications/me');
      if (resp && resp.data) {
        certifications.value = resp.data;
      }
    } finally {
      loading.value = false;
    }
  };

  const updateCertification = async (payload: any) => {
    try {
      submitting.value = true;
      await api.put(`/certifications/${payload.id}`, payload.data);
      await getMyCertifications();
      certificationFormOpen.value = false;
      clearCertificationFormData();
    } finally {
      submitting.value = false;
    }
  };

  const deleteCertification = async (payload: any) => {
    try {
      submitting.value = true;
      await api.delete(`/certifications/${payload.id}`, payload.data);
      await getMyCertifications();
      certificationFormOpen.value = false;
      clearCertificationFormData();
    } finally {
      submitting.value = false;
    }
  };

  const clearCertificationFormData = () => {
    certificationFormData.value = {
      id: null,
      title: null,
      type: null,
      expirationDate: null,
      issuedDate: null,
      issuedBy: null,
      validIn: [],
    };
  };

  const reset = () => {
    certifications.value = [];
    certificationFormOpen.value = false;
    clearCertificationFormData();
  };

  return {
    loading,
    certificationFormData,
    certifications,
    certificationFormOpen,
    submitting,
    getMyCertifications,
    updateCertification,
    deleteCertification,
    clearCertificationFormData,
    reset,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCertificationsStore, import.meta.hot));
}
