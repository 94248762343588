export default defineNuxtPlugin(() => {
  if (import.meta.client) {
    return;
  }

  const { isApp, isAndroid, serverUserAgent } = storeToRefs(useAuthStore());

  const userAgent = useRequestHeader('user-agent');

  serverUserAgent.value = userAgent;
  isApp.value = Boolean(userAgent?.includes('PCL_'));
  isAndroid.value = Boolean(userAgent?.includes('PCL_ANDROID'));
});
