import { acceptHMRUpdate, defineStore } from 'pinia';
import { computed, ref } from 'vue';
import _cloneDeep from 'lodash/cloneDeep.js';

import type { Alert, AlertCreate, AlertUpdate, EmptyAlert } from '@/types/alerts';

export const useAlertsStore = defineStore('alerts', () => {
  const api = useApi();

  const loading = ref(false);
  const submitting = ref(false);
  const alertFormOpen = ref(false);
  const alertData = ref<EmptyAlert | Alert>({
    id: null,
    name: null,
    active: true,
    maxDistance: null,
    proximity: null,
    proximityCity: null,
    proximityState: null,
    originStates: [],
    regions: [],
    originDisplay: '',
    originType: 'region',
    escortPositions: [],
    minRatePerMile: null,
    minRatePerDay: null,
    notifyOnZeroRate: true,
  });
  const originalAlertData = _cloneDeep(alertData.value);

  const alerts = ref<Alert[]>([]);

  const activeAlerts = computed(() => alerts.value.filter(alert => alert.active === true));

  const createAlert = async (payload: AlertCreate) => {
    const { trackIntercomEvent } = useIntercomStore();

    try {
      submitting.value = true;
      await api.post('/alerts', payload);
      await getAlerts();
      alertFormOpen.value = false;
      alertData.value = originalAlertData;

      trackIntercomEvent({ event: 'Alert Created', data: payload });
    } finally {
      submitting.value = false;
    }
  };

  const updateAlert = async ({ id, data }: { id: number, data: AlertUpdate }) => {
    const { trackIntercomEvent } = useIntercomStore();

    try {
      submitting.value = true;
      await api.put(`/alerts/${id}`, data);
      await getAlerts();
      alertFormOpen.value = false;
      alertData.value = originalAlertData;

      trackIntercomEvent({ event: 'Alert Updated', data });
    } finally {
      submitting.value = false;
    }
  };

  const deleteAlert = async (id: number) => {
    const { trackIntercomEvent } = useIntercomStore();

    try {
      submitting.value = true;
      await api.delete(`/alerts/${id}`);
      await getAlerts();
      alertFormOpen.value = false;
      alertData.value = originalAlertData;

      trackIntercomEvent({ event: 'Alert Deleted' });
    } finally {
      submitting.value = false;
    }
  };

  const getAlerts = async () => {
    const { updateIntercom } = useIntercomStore();

    try {
      loading.value = true;
      const resp = await api.get('/alerts/me');
      if (resp && resp.data) {
        alerts.value = resp.data;

        updateIntercom({ 'Active Alerts': activeAlerts.value.length });
      }
    } finally {
      loading.value = false;
    }
  };

  const clearAlertData = () => {
    alertData.value = originalAlertData;
  };

  const reset = () => {
    loading.value = false;
    submitting.value = false;
    alertFormOpen.value = false;
    alertData.value = originalAlertData;
    alerts.value = [];
  };

  return {
    loading,
    submitting,
    alertFormOpen,
    alertData,
    alerts,
    activeAlerts,
    createAlert,
    updateAlert,
    deleteAlert,
    getAlerts,
    clearAlertData,
    reset,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAlertsStore, import.meta.hot));
}
