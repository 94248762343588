import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';

import type { ApiPaginationData, ApiParams } from '~/types/api';

interface DirectoryApiParams extends ApiParams {
  serviceOffered: string;
  serviceState: string;
}

export const useDirectoryStore = defineStore('directory', () => {
  const api = useApi();

  const loading = ref(false);
  const directoryDetailOpen = ref(false);
  const profileData = ref({});
  const apiParams = ref<DirectoryApiParams>({
    page: 1,
    pageSize: 10,
    serviceOffered: '',
    serviceState: '',
  });
  const paginationData = ref<ApiPaginationData>({
    total: 0,
    hasPrevious: false,
    previousPage: null,
    hasNext: false,
    nextPage: null,
    pages: 0,
  });
  const profiles = ref([]);

  const getAllProfiles = async () => {
    try {
      loading.value = true;

      apiParams.value.serviceState = apiParams.value.serviceState || '';
      apiParams.value.serviceOffered = apiParams.value.serviceOffered || '';

      const resp = await api.get(
        '/profile',
        {
          params: {
            ...apiParams.value,
          },
        },
      );

      if (resp && resp.data) {
        profiles.value = resp.data.items;
        paginationData.value = {
          total: resp.data.total,
          hasPrevious: resp.data.hasPrevious,
          previousPage: resp.data.previousPage,
          hasNext: resp.data.hasNext,
          nextPage: resp.data.nextPage,
          pages: resp.data.pages,
        };
      }
    } finally {
      loading.value = false;
    }
  };

  const clearProfileData = () => {
    profileData.value = {};
  };

  const clearApiParams = () => {
    apiParams.value = {
      page: 1,
      pageSize: 10,
      serviceOffered: '',
      serviceState: '',
    };
  };

  const clearPaginationData = () => {
    paginationData.value = {
      total: 0,
      hasPrevious: false,
      previousPage: null,
      hasNext: false,
      nextPage: null,
      pages: 0,
    };
  };

  const reset = () => {
    profiles.value = [];
    clearProfileData();
    clearApiParams();
    clearPaginationData();
  };

  return {
    loading,
    directoryDetailOpen,
    profileData,
    apiParams,
    paginationData,
    profiles,
    getAllProfiles,
    clearProfileData,
    clearApiParams,
    clearPaginationData,
    reset,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDirectoryStore, import.meta.hot));
}
